<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div class="custom-tab-card">
      <div class="row">
        <div class="form-label-group col-md-4">
          <input
            name="employeeNameAr"
            id="employeeNameAr"
            type="text"
            v-model="employeeData.employeeNameAr"
            class="form-control"
            placeholder=" "
            maxlength="50"
          />
          <label for="employeeNameAr">{{ $t("employeeNameAr") }}</label>
          <img src="@/assets/images/man.svg" />
        </div>
        <div class="form-label-group col-md-4">
          <input
            name="employeeNameEn"
            id="employeeNameEn"
            type="text"
            v-model="employeeData.employeeNameEn"
            class="form-control"
            placeholder=" "
            maxlength="50"
          />
          <label for="employeeNameEn">{{ $t("employeeNameEn") }}</label>
          <img src="@/assets/images/man.svg" />
        </div>
        <div class="form-label-group col-md-4">
          <input
            name="employeeNameUnd"
            id="employeeNameUnd"
            type="text"
            v-model="employeeData.employeeNameUnd"
            class="form-control"
            placeholder=" "
            maxlength="50"
          />
          <label for="employeeNameUnd">{{ $t("employeeNameUnd") }}</label>
          <img src="@/assets/images/man.svg" />
        </div>
        <TelPicker
          :className="'col-md-6 col-lg-4'"
          :value="employeeData.employeeCCWithCCName"
          v-on:changeCC="employeeData.employeeCCWithCCName = $event"
        />
        <div class="form-label-group col-md-6 col-lg-4">
          <input
            name="employeePhone"
            id="employeePhone"
            type="text"
            v-model="employeeData.employeePhone"
            class="form-control"
            placeholder=" "
            maxlength="50"
          />
          <label for="employeePhone">{{ $t("phoneNumber") }}</label>
          <img src="@/assets/images/phone.svg" />
        </div>
        <div class="form-label-group col-md-6 col-lg-4">
          <input
            name="employeeUserName"
            id="employeeUserName"
            type="text"
            v-model="employeeData.employeeUserName"
            class="form-control"
            placeholder=" "
            maxlength="50"
          />
          <label for="employeeUserName">{{ $t("userName") }}</label>
          <img src="@/assets/images/man.svg" />
        </div>
        <div class="form-label-group col-md-6 col-lg-4">
          <input
            name="employeeEmail"
            id="employeeEmail"
            type="text"
            v-model="employeeData.employeeEmail"
            class="form-control"
            placeholder=" "
            maxlength="50"
          />
          <label for="employeeEmail">{{ $t("email") }}</label>
          <img src="@/assets/images/email.svg" />
        </div>

        <div class="form-label-group col-md-6 col-lg-4">
          <input
            name="employeeCode"
            id="employeeCode"
            type="text"
            v-model="employeeData.employeeCode"
            class="form-control"
            placeholder=" "
            maxlength="50"
          />
          <label for="employeeCode">{{ $t("employeeCode") }}</label>
          <img src="@/assets/images/number.svg" />
        </div>
        <div class="form-label-group col-md-6 col-lg-4">
          <b-form-select
            v-model="employeeData.employeeTypeToken"
            :options="employeeTypeTokenOptions"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/man.svg" />
        </div>

        <div class="form-label-group col-md-6">
          <b-form-select
            v-model="employeeData.establishmentRoleToken"
            :options="establishmentRoleTokenOptions"
            class="form-control"
          ></b-form-select>
          <img src="@/assets/images/establishment-roles.svg" />
        </div>
        <CustomCheckbox
          :className="'col-md-6'"
          :value="employeeData.employeeActiveStatus"
          :title="$t('employeeActiveStatus')"
          v-on:changeValue="employeeData.employeeActiveStatus = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { STATUS, DEFAULT_IMG } from "@/utils/constants";

import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import TelPicker from "@/components/general/TelPicker.vue";
import EstablishmentRole from "@/models/settings/settingsOther/establishmentRoles/EstablishmentRole";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";

export default {
  name: "EmployeeBasicData",
  mixins: [createToastMixin],
  components: { PreLoader, ExceptionWithImg, CustomCheckbox, TelPicker },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      defaultImg: DEFAULT_IMG,

      employeeGenderTokenOptions: [],
      employeeQualificationTokenOptions: [],
      employeeMaritalTokenOptions: [],
      employeeMilitaryTokenOptions: [],
      employeeTypeTokenOptions: [],
      generalSpecialtyTokenOptions: [],
      specialSpecialtyTokenOptions: [],
      bloodTypeTokenOptions: [],
      scientificDegreeTokenOptions: [],
      establishmentRoleData: new EstablishmentRole(),
      establishmentRoleTokenOptions: [],
    };
  },
  props: ["employeeData"],
  watch: {
    "employeeData.employeeCCWithCCName": function (val) {
      let cc = val.split(" ");
      this.employeeData.employeeCountryCode = cc[1];
      this.employeeData.employeeCountryCodeName = cc[0];
    },
  },
  methods: {
    async getDialogOfEstablishmentRoles() {
      this.isLoading = true;
      this.establishmentRoleTokenOptions = [];
      this.establishmentRoleTokenOptions.push({
        value: "",
        text: this.$t("setting.others.establishmentRolesMsgs.select"),
      });
      try {
        let response =
          await this.establishmentRoleData.getDialogOfEstablishmentRoles(
            this.language,
            this.userAuthorizeToken
          );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.establishmentRoleTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async changeSector() {
      this.employeeData.employeeJobToken = "";
      this.getDialogOfJobs(this.employeeData.employeeSectorToken);
    },
    async getDialogOfEmployeeTypes() {
      this.isLoading = true;
      this.employeeTypeTokenOptions = [];
      this.employeeTypeTokenOptions.push({
        value: "",
        text: this.$t("selectEmployeeType"),
      });
      let employeeTypes =
        this.$store.getters.userData.constantLists.listEmployeeType;
      for (let item in employeeTypes) {
        this.employeeTypeTokenOptions.push({
          value: employeeTypes[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            employeeTypes[item]["itemNameAr"],
            employeeTypes[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },

    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  async created() {
    this.getDialogOfEmployeeTypes();
    this.getDialogOfEstablishmentRoles();
  },
};
</script>

<style lang="scss"></style>
