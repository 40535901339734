<template>
  <div class="">
    <div class="custom-tab-card">
      <div class="row">
        <div class="form-label-group col-md-6">
          <input
            name="employeeNationalNumber"
            id="employeeNationalNumber"
            type="text"
            v-model="employeeData.employeeNationalNumber"
            class="form-control"
            placeholder=" "
            maxlength="50"
          />
          <label for="employeeNationalNumber">{{ $t("nationalNumber") }}</label>
          <img src="@/assets/images/nationalNumber.svg" />
        </div>
        <div class="form-label-group col-md-6">
          <input
            name="employeePassportNumber"
            id="employeePassportNumber"
            type="text"
            v-model="employeeData.employeePassportNumber"
            class="form-control"
            placeholder=" "
            maxlength="50"
          />
          <label for="employeePassportNumber">{{ $t("passportNumber") }}</label>
          <img src="@/assets/images/passportNumber.svg" />
        </div>
        <!-- <DatePicker
          :className="'col-md-6 col-lg-6'"
          :id="'employeeNationalNumberExpireDate'"
          :value="employeeData.employeeNationalNumberExpireDate"
          :title="$t('nationalNumberExpireDate')"
          :language="language"
          v-on:changeValue="
            employeeData.employeeNationalNumberExpireDate = $event
          "
        /> -->

        <div class="form-label-group col-md-6">
          <input
            name="employeeDrivingLicenceNumber"
            id="employeeDrivingLicenceNumber"
            type="text"
            v-model="employeeData.employeeDrivingLicenceNumber"
            class="form-control"
            placeholder=" "
            maxlength="50"
          />
          <label for="employeeDrivingLicenceNumber">{{
            $t("drivingLicenceNumber")
          }}</label>
          <img src="@/assets/images/drivingLicense.svg" />
        </div>

        <DatePicker
          :className="'col-md-6'"
          :id="'employeeDrivingLicenceExpireDate'"
          :value="employeeData.employeeDrivingLicenceExpireDate"
          :title="$t('drivingLicenceExpireDate')"
          :language="language"
          v-on:changeValue="
            employeeData.employeeDrivingLicenceExpireDate = $event
          "
        />

        <!-- <DatePicker
          :className="'col-md-4 col-lg-4'"
          :id="'employeePassportExpireDate'"
          :value="employeeData.employeePassportExpireDate"
          :title="$t('passportExpireDate')"
          :language="language"
          v-on:changeValue="employeeData.employeePassportExpireDate = $event"
        /> -->
        <CustomSelectBox
          :className="'col-md-4'"
          :id="'employeeCitizenShipTypeToken'"
          :value="employeeData.employeeCitizenShipTypeToken"
          :options="employeeCitizenShipTypeTokenOptions"
          v-on:changeValue="employeeData.employeeCitizenShipTypeToken = $event"
          :title="$t('selectCitizenShipType')"
          :imgName="'citizenship.svg'"
        />
        <DatePicker
          :className="'col-md-4'"
          :id="'employeeResidencyStartDate'"
          :value="employeeData.employeeResidencyStartDate"
          :title="$t('employeeResidencyStartDate')"
          :language="language"
          v-on:changeValue="employeeData.employeeResidencyStartDate = $event"
        />
        <DatePicker
          :className="'col-md-4'"
          :id="'employeeResidencyEndDate'"
          :value="employeeData.employeeResidencyEndDate"
          :title="$t('employeeResidencyEndDate')"
          :language="language"
          v-on:changeValue="employeeData.employeeResidencyEndDate = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DEFAULT_IMG } from "@/utils/constants";
import createToastMixin from "@/utils/create-toast-mixin";
import DatePicker from "@/components/general/DatePicker.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";

export default {
  name: "EmployeeIdentifiersCardsData",
  mixins: [createToastMixin],
  components: { DatePicker, CustomSelectBox },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      defaultImg: DEFAULT_IMG,
      employeeCitizenShipTypeTokenOptions: [],
    };
  },
  props: ["employeeData"],
  methods: {
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
    async getDialogOfCitizenShipType() {
      this.isLoading = true;
      this.employeeCitizenShipTypeTokenOptions = [];
      this.employeeCitizenShipTypeTokenOptions.push({
        value: "",
        text: this.$t("selectCitizenShipType"),
      });
      let itemsData =
        this.$store.getters.userData.constantLists.listCitizenShipType;
      for (let item in itemsData) {
        this.employeeCitizenShipTypeTokenOptions.push({
          value: itemsData[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            itemsData[item]["itemNameAr"],
            itemsData[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
  },
  watch: {},
  async created() {
    this.getDialogOfCitizenShipType();
  },
};
</script>

<style lang="scss"></style>
