<template>
  <div class="">
    <form autocomplete="off">
      <b-tabs content-class="custom-tab-inner-content">
        <b-tab :title-item-class="'custom-tab-inner-li'">
          <EmployeeBasicData :employeeData="employeeData" />
          <template #title>
            <span
              v-b-popover.hover.bottomright="
                $t('employeeTabs.mainTabs.basicData')
              "
            >
              <img
                :src="require('@/assets/images/info.svg')"
                :onerror="`this.src='${defaultImg}'`"
                class="icon-tab"
              />
              <span>{{ $t("employeeTabs.mainTabs.basicData") }}</span></span
            >
          </template>
        </b-tab>

        <b-tab :title-item-class="'custom-tab-inner-li'">
          <EmployeePersonalData
            :employeeData="employeeData"
            :imageSrc="imageSrc"
          />
          <template #title>
            <span
              v-b-popover.hover.bottomright="
                $t('employeeTabs.mainTabs.personalData')
              "
            >
              <img
                :src="require('@/assets/images/info.svg')"
                :onerror="`this.src='${defaultImg}'`"
                class="icon-tab"
              />
              <span>{{ $t("employeeTabs.mainTabs.personalData") }}</span></span
            >
          </template>
        </b-tab>

        <b-tab :title-item-class="'custom-tab-inner-li'">
          <EmployeeIdentifiersCardsData :employeeData="employeeData" />
          <template #title>
            <span
              v-b-popover.hover.bottomright="
                $t('employeeTabs.mainTabs.identifiersCardsData')
              "
            >
              <img
                :src="require('@/assets/images/info.svg')"
                :onerror="`this.src='${defaultImg}'`"
                class="icon-tab"
              />
              <span>{{
                $t("employeeTabs.mainTabs.identifiersCardsData")
              }}</span></span
            >
          </template>
        </b-tab>
      </b-tabs>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateEmployee"
        >
          {{ submitName }}
        </button>
        <router-link to="/employees" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { DEFAULT_IMG } from "@/utils/constants";
import EmployeePersonalData from "./employeeFormComponents/EmployeePersonalData.vue";
import EmployeeBasicData from "./employeeFormComponents/EmployeeBasicData.vue";
import EmployeeIdentifiersCardsData from "./employeeFormComponents/EmployeeIdentifiersCardsData.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EmployeeForm",
  mixins: [createToastMixin],
  components: {
    EmployeePersonalData,
    EmployeeBasicData,
    EmployeeIdentifiersCardsData,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      defaultImg: DEFAULT_IMG,
    };
  },
  props: ["employeeData", "imageSrc", "submitName"],
  methods: {
    async addOrUpdateEmployee() {
      this.$emit("addOrUpdateEmployee");
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  watch: {
    "employeeData.employeeCCWithCCName": function (val) {
      let cc = val.split(" ");
      this.employeeData.employeeCountryCode = cc[1];
      this.employeeData.employeeCountryCodeName = cc[0];
    },
  },
  async created() {
    // setTimeout(
    //   () => this.getDialogOfJobs(this.employeeData.employeeSectorToken),
    //   2000
    // );
  },
};
</script>

<style lang="scss"></style>
